<template>
  <!-- 企业服务 -->
  <div class="box">
    <!-- 头部 -->
    <div class="logoTop">
      <span><img src="../../assets/anluoyun.png" alt="" style="width:13%" /></span>
      <span @click="viewList()"><van-icon name="bars" /></span>
    </div>
    <van-popup
      v-model="tabShow"
      class="tabShow"
      position="right"
      :style="{ height: '100%' }"
    >
      <div style="margin:15px"> 
        <img src="../../assets/anluoyun.png" alt="" style="width:80%;margin:0 10%">
      </div>
      <ul>
        <li><router-link to="/anLuoYun">网站首页</router-link></li>
        <li>
          <router-link to="/anLuoYun/about">关于我们</router-link>
        </li>
        <li class="select"><router-link to="/anLuoYun/serve">企业项目</router-link></li>
        <li><router-link to="/anLuoYun/recruit">企业招聘</router-link></li>
        <li><router-link to="/anLuoYun/message">企业资讯</router-link></li>
        <li><router-link to="/anLuoYun/contact">联系我们</router-link></li>
      </ul>
    </van-popup>
    <!-- 标题 -->
    <div class="title">
      <van-tabs type="card" color="#0076f6">
        <van-tab title="智慧社区"></van-tab>
        <van-tab title="精准帮扶"></van-tab>
        <van-tab title="核酸检测"></van-tab>
        <van-tab title="其他项目"></van-tab>
      </van-tabs>
    </div>
    <!-- 内容 -->
    <article class="center">
      <div class="boxList">
        <div>光合城智慧社区</div>
        <div class="van-multi-ellipsis--l2">智慧社区是指通过利用各种智能技术和方式，整合社区现有的各类服务资源，为社区群众提供政务、商务、娱乐、教育、医护及生活互助等多种便捷服务的模式。从应用方向来看，“智慧社区”应实现“以智慧政务提高办事效率，以智慧民生改善人民生活，以智慧家庭打造智能生活，以智慧小区提升社区品质”的目标。</div>
      </div>
    </article>
    <!-- 底部 -->
    <aside class="buttonVal">
      <div>电 话：18837992186</div>
      <div>地 址：洛阳市经济技术开发区长夏门街32号863创智广场1 幢2201</div>
      <div>版 权：河南安洛云信息科技有限公司</div>
      <div>备案号：豫ICP备17050318号-1</div>
    </aside>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabShow: false,
    };
  },
  methods: {
    // 顶部tab菜单显示
    viewList() {
      if (this.tabShow == true) {
        this.tabShow = false;
      } else {
        this.tabShow = true;
      }
    },
    pushTo(row) {},
  },
};
</script>
<style scoped>
.logoTop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 65px;
  background: #fff;
  box-shadow: 0px 1px 5px #ccc;
}
.logoTop span:nth-child(1) {
  position: absolute;
  left: 15px;
  top: 10px;
}
.logoTop span:nth-child(2) {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 37px;
}
.tabShow {
  width: 130px;
}
.tabShow li {
  padding: 8px 0px;
  text-align: center;
  color: #000;
}
.tabShow a {
  color: #000;
}
.tabShow .select {
  background: #ccc;
}
.tabShow .select a {
  color: #0076f6;
}
.title {
  margin: 90px 15px 0 15px;
  padding-bottom: 10px; 
}
.center {
  margin-top: 5px;
  font-size: 15px;
  line-height: 30px;
}
.buttonVal {
  position: absolute;
  bottom: 0px;
  padding: 8px 15px;
  background: #333333;
  font-size: 14px;
  color: #fff;
}
.buttonVal div {
  margin: 5px;
}
.child-view {
  height: 100%;
}
.boxList {
  padding: 8px 15px;
  border-bottom: 1px dashed #ccc;
}
.boxList div:nth-child(1){

}
.boxList div:nth-child(2){
  font-size: 12px;
}
</style>